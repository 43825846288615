
<template>
  <div class="app_con">
    <app-list
      ref="applist"
      :remote="remote"
      :props="props"
      :searchQuery="searchQuery"
      :searchControl="searchControl"
      :apiName="apiName"
      :editTitle="'会员卡'"
      :buttonShow="false"
      isInline
      :isSelectShow="false"
    >
      <div slot="toolSub" selecTionArr="">
        <el-button type="warning" @click="exportExcel" icon="el-icon-upload2"
          >导出</el-button
        >
      </div>
      <div class="search" slot="tool">
        <el-input
          clearable
          style="width: 317px"
          placeholder="输入会员卡号"
          v-model="searchQuery.cardNo"
          class="searchInput"
        >
          <template slot="prepend">会员卡号</template>
        </el-input>
        <el-input
          clearable
          style="width: 317px"
          placeholder="输入商户姓名"
          v-model="searchQuery.merchantPersonnelName"
          class="searchInput"
        >
          <template slot="prepend">商户姓名</template>
        </el-input>
        <el-input
          clearable
          style="width: 317px"
          placeholder="输入摊位号"
          v-model="searchQuery.merchantStallNo"
          class="searchInput"
        >
          <template slot="prepend">摊位号</template>
        </el-input>
        <div class="searchInput el-input-group">
          <div class="el-input-group__prepend">起始日期</div>
          <el-date-picker
            v-model="searchQuery.startDate"
            type="datetime"
            placeholder="选择日期"
            value-format="yyyy-MM-dd HH:mm:ss"
          ></el-date-picker>
        </div>
        <div class="searchInput el-input-group">
          <div class="el-input-group__prepend">结束日期</div>
          <el-date-picker
            v-model="searchQuery.endDate"
            type="datetime"
            placeholder="选择日期"
            value-format="yyyy-MM-dd HH:mm:ss"
          ></el-date-picker>
        </div>
      </div>
    </app-list>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      remote: api.common,
      apiName: "/vip/consume",
      searchQuery: {
        merchantPersonnelName: "",
        merchantStallNo: "",
        startDate: "",
        endDate: "",
        refurbishNum: 0,
      },
      dialogVisible: false,
      loading: false,
      searchControl: {
        firstSoltSpan: [0, 0, 0, 0],
        secondSoltSpan: [3, 3, 3, 3],
        thirdSoltSpan: [21, 21, 21, 21],
      },
      props: [
        {
          label: "订单编号",
          prop: "id",
          align: "center",
        },
        {
          label: "消费日期",
          prop: "createTime",
          align: "center",
        },
        {
          label: "金额",
          prop: "money",
          align: "center",
        },
        {
          label: "商户名称",
          prop: "merchantPersonnelName",
          align: "center",
        },
        {
          label: "摊位号",
          prop: "merchantStallNo",
          align: "center",
        },

        {
          label: "会员卡号",
          prop: "cardNo",
          align: "center",
        },
        {
          label: "扣款前余额",
          prop: "beforeBalance",
          align: "center",
        },
        {
          label: "当前余额",
          prop: "afterBalance",
          align: "center",
        },
      ],
    };
  },
  computed: {},
  created() {
    let query = this.$route.query;
    if (query.data) {
      let data = JSON.parse(decodeURIComponent(query.data));
      this.searchQuery.merchantPersonnelName = data.merchantPersonnelName;
      this.searchQuery.startDate = data.startDate;
      this.searchQuery.endDate = data.endDate;
      this.searchQuery.merchantStallNo = data.merchantStallNo;
      this.searchQuery.refurbishNum++;
    }
  },
  mounted() {
    this.$nextTick(() => {
      console.log(this.$refs["applist"]);
      this.$refs["applist"].getItems();
    });
  },
  methods: {
    // 导出
    async exportExcel() {
      const { searchQuery } = this;
      await api.common.download({
        apiName: "/vip/consume/exportExcel",
        ...searchQuery,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
}
.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
